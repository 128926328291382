import Component from '../classes/Component';
import { $events } from '../helpers/events';
import { $dom } from '../helpers/dom';

const { get, addClass } = $dom;

const initialLoadSelector = '.js-initial-load';

const isFulfilled = 'is-fulfilled';

export function imageLoad() {

  const checkLoad = event => {
    const target = event.target.closest(initialLoadSelector);

    addClass(target.parentNode, isFulfilled);

    if ((get('h4', target.parentNode) instanceof HTMLElement)) {
      get('h4', target.parentNode).innerHTML = 'Ваше фото загружено';
    }

    const inputTarget = event.target;
    const file = inputTarget.files[0];

    console.log(target);

    const pictureImage = get('.picture__image', target.parentNode);
    // const pictureImageTxt = "Choose an image";
    // pictureImage.innerHTML = pictureImageTxt;

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function (e) {
        const readerTarget = e.target;

        const img = document.createElement("img");
        img.src = readerTarget.result;
        img.classList.add("picture__img");

        pictureImage.innerHTML = "";
        pictureImage.appendChild(img);

        addClass(pictureImage.parentNode, 'is-loaded');
      });

      reader.readAsDataURL(file);
    } else {
      // pictureImage.innerHTML = pictureImageTxt;
    }
  }

  return new Component({
    name: 'imageLoad',
    requiredTargets: initialLoadSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('change', initialLoadSelector, checkLoad)
    },
    onDestroy() {
      $events.delegate
        .off('change', initialLoadSelector, checkLoad)
    }
  })
}