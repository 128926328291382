import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';

const { get, getAll, attr, addClass, removeClass, toggleClass, hasClass } = $dom;

const { active, invalid } = variables.classNames;

const initialSelector = '.js-initial';

const initialInputAgeSelector = '.js-initial-age';
const initialInputDateOfBirthSelector = '.js-initial-date-of-birth';
const initialAgeContentSelector = '.js-initial-age-content';

const initialAddressSelector = '.js-initial-address';
const initialAddressContentSelector = '.js-initial-address-content';
const initialAddressFormSelector = '.js-initial-address-form';

// const initialLoadSelector = '.js-initial-load';
const initialLoadImgSelector = '.js-initial-load-img';

const initialDocumentsSelector = '.js-documents-selector';
const initialDocumentsFieldSelector = '.js-field';

export function initialSteps() {

  const checkAddress = event => {
    const target = event.target.closest(initialAddressSelector);

    const content = get(initialAddressContentSelector, target.parentNode.parentNode.parentNode.parentNode);

    const form = target.parentNode.parentNode;

    const firstForm = getAll('.js-field-validate', initialAddressFormSelector);
    const secondForm = getAll('.js-field-validate', form);

    firstForm.forEach((element, i) => {
      secondForm[i].value = element.value;
    });

    toggleClass(target, active);
    toggleClass(content, 'd-none');
  }

  const checkDocuments = event => {
    const target = event.target.closest(initialDocumentsSelector);

    console.log(target.value);

    getAll(initialDocumentsFieldSelector).forEach(element => {
      console.log(element);

      removeClass(element, 'is-hidden');
    })

    switch (target.value) {
      case '3': {
        
      }
      break;
      
      case '4': {
        getAll(initialDocumentsFieldSelector).forEach(element => {
          console.log(element);

          addClass(element, 'is-hidden');
        })
      }
      break;
    }
  }

  return new Component({
    name: 'initialSteps',
    requiredTargets: initialSelector,
    onCreate() { },
    onInit() {

      const docInput = get(initialDocumentsSelector);

      console.log(docInput.value);

      getAll(initialDocumentsFieldSelector).forEach(element => {
        console.log(element);

        removeClass(element, 'is-hidden');
      })

      switch (docInput.value) {
        case '3': {

        }
          break;

        case '4': {
          getAll(initialDocumentsFieldSelector).forEach(element => {
            console.log(element);

            addClass(element, 'is-hidden');
          })
        }
          break;
      }

      $events.delegate
        // .on('change', initialInputDateOfBirthSelector, checkAge)
        .on('click', initialAddressSelector, checkAddress)
        .on('change', initialDocumentsSelector, checkDocuments)
    },
    onDestroy() {
      $events.delegate
        // .off('change', initialInputDateOfBirthSelector, checkAge)
        .off('click', initialAddressSelector, checkAddress)
        .off('change', initialDocumentsSelector, checkDocuments)
    }
  })
}