import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';

const { attr } = $dom;

const numberInputSelector = '.js-numbers-only';

const dataMax = 'data-max';
const dataMin = 'data-min';

export function numbersInput() {

  const umberInputHandler = event => {
    const input = event.target.closest(numberInputSelector);

    input.value = input.value.replace(/\D+/g, '');

    const max = Number(attr(input, dataMax)) || null;

    const n = input.value.replace('%', '');
    if (n >= 0 && n <= max) {
      input.value = input.value.replace('%', '')
    } else {
      input.value = n.slice(0, -1)
    }
  }

  return new Component({
    name: 'numbersInput',
    requiredTargets: numberInputSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('input', numberInputSelector, umberInputHandler);
    },
    onDestroy() {
      $events.delegate
        .off('input', numberInputSelector, umberInputHandler);
    }
  })
}